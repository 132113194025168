import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  selectedRules: string[];
}

const initialState: InitialState = {
  selectedRules: []
};

export const assignedRulesSlice = createSlice({
  name: 'assignedRules',
  initialState,
  reducers: {
    setSelectedRules: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedRules = payload;
    },
    addSelectedRule: (state, { payload }: PayloadAction<string>) => {
      state.selectedRules = [...state.selectedRules, payload];
    },
    removeSelectedRule: (state, { payload }: PayloadAction<string>) => {
      state.selectedRules = state.selectedRules.filter((item) => item !== payload);
    }
  }
});

export const { setSelectedRules, addSelectedRule, removeSelectedRule } = assignedRulesSlice.actions;
