import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Business } from 'models/Business';
import { ProductXrefConfigurationModel } from 'models/RudyConfigurationModels';

export interface InitialState {
  inheritanceTypeFilter: ('Division' | 'Business' | 'BusinessWithDivisionOverride')[];
  customerSearch?: string;
  cachedBusinessList?: Business[];
  cachedCatalogConfig?: ProductXrefConfigurationModel[];
}

export const initialState: InitialState = {
  inheritanceTypeFilter: ['Business', 'Division', 'BusinessWithDivisionOverride'],
  customerSearch: undefined,
  cachedBusinessList: undefined,
  cachedCatalogConfig: undefined
};

export const businessListSlice = createSlice({
  name: 'businessList',
  initialState,
  reducers: {
    setInheritanceTypeFilter: (state, { payload }: PayloadAction<InitialState['inheritanceTypeFilter']>) => {
      state.inheritanceTypeFilter = payload;
    },
    setCustomerSearch: (state, { payload }: PayloadAction<InitialState['customerSearch']>) => {
      state.customerSearch = payload;
    },
    setCachedBusinessList: (state, { payload }: PayloadAction<InitialState['cachedBusinessList']>) => {
      state.cachedBusinessList = payload;
    },
    setCachedCatalogConfig: (state, { payload }: PayloadAction<InitialState['cachedCatalogConfig']>) => {
      state.cachedCatalogConfig = payload;
    }
  }
});

export const { setInheritanceTypeFilter, setCustomerSearch, setCachedBusinessList, setCachedCatalogConfig } = businessListSlice.actions;
