import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';

export const UserForm = (): JSX.Element => {
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <TextInput fieldName="givenName" label="First Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="familyName" label="Last Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="phoneNumber" label="Phone Number" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="email" label="Email" />
        </Col>
      </Row>
    </Form>
  );
};
