import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { List } from 'antd';
import { encodeValue } from 'common/helpers/encodeValue';
import { BlockLoader } from 'components/atoms/BlockLoader';
import WimsicalError from 'components/atoms/WimsicalError/WimsicalError';
import { LoadingMore } from 'components/common/LoadMore';
import { AssignProductsModal } from 'components/molecules/AssignProductsModal';
import { ProductSearchItem } from 'components/molecules/ProductSearchItem';
import { ProductSearchControls } from 'components/organisms/ProductSearchControls';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { useGetReleasedProductsQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { incrementOffset } from 'redux/services/donaldGrump/releasedProductsParams';
import { useCheckProductXrefItemAssignedQuery } from 'redux/services/floBear/floBearApi';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const ProductSearchPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id: customerId } = useParams();

  /* ******************** API Hooks ******************** */
  const { donaldGrumpReleasedProductsParams } = useAppSelector((state) => state);

  const { data, isLoading, isFetching, isError, error } = useGetReleasedProductsQuery(donaldGrumpReleasedProductsParams, { skip: !donaldGrumpReleasedProductsParams.dataAreaId });
  const { data: assignedXrefItems, isLoading: isAssignedLoading } = useCheckProductXrefItemAssignedQuery(
    { customerId: customerId as string, productNumbers: data?.data.map((product) => encodeValue({ doubleEncode: false, value: product.productNumber })) || [] },
    { skip: !data || !data.totalCount || isError || !customerId }
  );

  /* ******************** Functions / Variables ******************** */
  const hasMore = Boolean(data && data.totalCount > data.data.length);

  const handleLoadMore = (): void => {
    dispatch(incrementOffset());
  };

  useEffect(() => {
    if (!isLoading && !isFetching && donaldGrumpReleasedProductsParams.offset === 0 && hasMore) dispatch(incrementOffset());
  }, [dispatch, donaldGrumpReleasedProductsParams.offset, hasMore, isFetching, isLoading]);

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <>
      <ProductSearchControls />
      <InfiniteScroll next={handleLoadMore} hasMore={hasMore} loader={<LoadingMore />} dataLength={data ? data.data.length : 0}>
        <List
          loading={{
            indicator: <BlockLoader direction="loader loader--slideUp" />,
            spinning: isLoading || (isFetching && donaldGrumpReleasedProductsParams.offset === 0),
            size: 'large',
            wrapperClassName: isLoading ? 'list-loading' : ''
          }}
          grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5 }}
          dataSource={data?.data}
          renderItem={(item): JSX.Element => (
            <ProductSearchItem
              product={item}
              isAssigned={!!assignedXrefItems?.data.find((assigned) => assigned.productNumber === item.productNumber)}
              isAssignedLoading={isAssignedLoading}
              isRecovery={!!assignedXrefItems?.data.find((assigned) => assigned.productNumber === item.productNumber && assigned.isDeleted)}
            />
          )}
          rowKey={(item): string => item.productNumber}
          style={{ overflow: 'hidden' }}
        />
      </InfiniteScroll>
      <AssignProductsModal />
    </>
  );
};
