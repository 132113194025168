import { Avatar, Button, List, Space, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { DivisionList } from 'components/molecules/DivisionList';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { setCustomerSearch } from 'redux/slices/businessListSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

const { Text } = Typography;

interface Props {
  business: Business;
}

const styles: InlineStylesModel = {
  altTitle: {
    fontWeight: '100',
    color: '#18a799',
    marginBottom: 0
  },
  container: {
    alignItems: 'center'
  },
  title: {
    fontWeight: '100',
    marginBottom: 0
  },
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 10,
    borderRadius: 5
  }
};

export const BusinessListItem = ({ business }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [showDivision, _setShowDivisions] = useState(false);
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showDivision ? height : 0
  });

  /* ******************** Functions ******************** */
  const handleToggleShowDivision = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    _setShowDivisions((prevState) => !prevState);
  };

  const handleEditBusiness = (): void => {
    navigate(`/business/${business.id}`);
    dispatch(setCustomerSearch(''));
  };

  useEffect(() => {
    if (customerSearch) {
      _setShowDivisions(true);
    }
    if (!business.divisions.length) _setShowDivisions(false);
  }, [business.divisions.length, customerSearch]);

  useEffect(() => {
    if (customerSearch === '') {
      _setShowDivisions(false);
    }
  }, [customerSearch]);

  /* ******************** Render ******************** */
  return (
    <>
      <List.Item
        onClick={business.divisions.length ? handleToggleShowDivision : undefined}
        actions={[
          <Button onClick={handleEditBusiness} type="primary" key="edit-business">
            Edit
          </Button>,
          <Button disabled={!business.divisions.length} style={{ width: 115 }} type="text" onClick={handleToggleShowDivision} key="show-divisions" icon={<CaretSpinIcon isOpen={showDivision} />}>
            {showDivision ? 'Collapse' : 'Expand'}
          </Button>
        ]}
        style={business.divisions.length ? { ...styles.wrapper, ...{ cursor: 'pointer' } } : styles.wrapper}>
        <List.Item.Meta
          avatar={<Avatar src={business.logoUrl} />}
          title={
            <Space>
              <Typography.Title level={5} style={styles.title}>
                {business.name}
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.title}>
                |
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                {business.erpId}
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.title}>
                |
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                {`${business.divisions.length} Divisions`}
              </Typography.Title>
            </Space>
          }
          style={styles.container}
        />
      </List.Item>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
        {showDivision && (
          <div ref={ref as LegacyRef<HTMLDivElement>}>
            <DivisionList divisions={business.divisions} />
          </div>
        )}
      </animated.div>
    </>
  );
};
