import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { InputOption } from 'components/molecules/AssignedRulesEditModal';
import { AssignedProductsFormLabels, AssignedProductsUpdateForm } from 'models/UpdateProductXref';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

interface Props {
  availableSelectOptions: (keyof AssignedProductsUpdateForm)[];
  option: InputOption;
  selectField: (newValue: keyof AssignedProductsUpdateForm, oldValue: keyof AssignedProductsUpdateForm) => void;
  removeField: (fieldName: string) => void;
  changeField: (fieldName: string) => void;
  toggleFields: (fieldName: string) => void;
}

export const AssignedRulesEditItem = ({ option, availableSelectOptions, selectField, removeField, changeField, toggleFields }: Props): JSX.Element => {
  const { id: entityId } = useParams();
  /* ******************** Hooks ******************** */
  const { data: xrefConfig } = useGetXrefConfigQuery({ customerId: entityId as string }, { skip: !entityId });

  const selectOptions: DefaultOptionType[] = availableSelectOptions.map((item) => ({
    label: item === 'alternateItemId' ? xrefConfig?.alternateItemIdLabel : AssignedProductsFormLabels[item],
    value: item
  }));

  const handleSelectField = (newValue: any): void => {
    changeField(option.value);
    toggleFields(newValue);
    selectField(newValue, option.value);
  };

  return (
    <>
      <Col span={8}>
        <Select
          options={selectOptions}
          value={{ label: option.value === 'alternateItemId' ? xrefConfig?.alternateItemIdLabel : AssignedProductsFormLabels[option.value], value: option.value }}
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={false}
          onChange={handleSelectField}
          filterSort={(optionA, optionB): number => (optionA.label as string)?.toLowerCase().localeCompare((optionB.label as string)?.toLowerCase())}
        />
      </Col>
      <Col span={14}>{option.render}</Col>
      <Col span={2}>
        <Button onClick={(): void => removeField(option.value)} danger size="small" type="dashed">
          <DeleteOutlined />
        </Button>
      </Col>
    </>
  );
};
