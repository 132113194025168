import { Space } from 'antd';
import { AlternateItemIdFilter, CustomerItemDescriptionFilter, DefaultPurchaseVendorFilter, IsActiveFilter, IsOverriddenFilter, IsRtuFilter, ProductNumberFilter } from 'components/atoms/RulesFilters';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

export const RulesFilters: React.FC = () => {
  const { id: customerId } = useParams();

  const { data: xrefConfig } = useGetXrefConfigQuery({ customerId: customerId as string }, { skip: !customerId });

  return (
    <Space style={{ marginTop: '10px' }}>
      <ProductNumberFilter />
      <AlternateItemIdFilter />
      <IsActiveFilter />
      <IsRtuFilter />
      <CustomerItemDescriptionFilter />
      <DefaultPurchaseVendorFilter />
      {xrefConfig?.dataInheritanceLevel === 'BusinessWithDivisionOverride' && <IsOverriddenFilter />}
    </Space>
  );
};
