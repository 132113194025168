import { RollbackOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message } from 'antd';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoverProductXrefItemMutation } from 'redux/services/floBear/floBearApi';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { useAppSelector } from 'redux/store';

export const RecoverRulesContextMenu: React.FC = ({ children }) => {
  const { id: customerId } = useParams();
  const { pathname } = useLocation();
  /* ******************** Hooks ******************** */
  const { selectedRules } = useAppSelector((state) => state.assignedRules);
  const { data: catalogConfiguration } = useGetXrefConfigQuery({ customerId: customerId as string });

  const [recoverXrefItem] = useRecoverProductXrefItemMutation();

  const isBusinessInheritance = catalogConfiguration?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');

  /* ******************** Functions / Variables ******************** */
  const handleRestoreRules = async (): Promise<void> => {
    if (!customerId) {
      message.error('No entity selected!');

      return;
    }

    for (const productId of selectedRules) {
      try {
        await recoverXrefItem({ customerId, productId }).unwrap();
      } catch {
        message.error(`Business product xref item ${productId} failed to be recovered!`);
      }
    }

    message.success(`Business product xref item(s) were successfully recovered!`);
  };

  const menu = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" icon={<RollbackOutlined />} onClick={handleRestoreRules} disabled={isBusinessInheritance}>
        Restore
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['contextMenu']}>
      <div className="site-dropdown-context-menu">{children}</div>
    </Dropdown>
  );
};
