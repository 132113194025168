import { Menu } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';

const styles: InlineStylesModel = {
  container: {
    borderRadius: 5
  }
};

export const RulesNavigation = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const [activeNav, _setActiveNav] = useState<string>('assigned-rules');
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isBusinessEdit = pathname.includes('business/');
  const isRecoverPage = pathname.includes('/recover');

  /* ****************** API Hooks ****************** */
  const { data: divisionXrefConfig } = useGetXrefConfigQuery({ customerId: id as string }, { skip: !id });

  /* ****************** Variables ****************** */
  const isBusinessOnly = divisionXrefConfig?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');

  /* ******************** Functions ******************** */
  const handleNavigationChange = (e: any): void => {
    switch (e.key) {
      case 'assigned-rules':
        navigate(`/${isBusinessEdit ? 'business' : 'division'}/${id}`);
        break;

      case 'product-search':
        navigate(`/${isBusinessEdit ? 'business' : 'division'}/${id}/search`);
        break;

      default:
        break;
    }
  };

  /* ******************** Effects ******************** */
  useEffect(() => {
    if (pathname.includes('search')) {
      _setActiveNav('product-search');
    } else {
      _setActiveNav('assigned-rules');
    }
  }, [pathname]);

  return (
    <Menu disabledOverflow={true} onClick={handleNavigationChange} activeKey={activeNav} mode="horizontal" style={styles.container}>
      <Menu.Item key="assigned-rules">Xref Items</Menu.Item>
      <Menu.Item key="product-search" disabled={isRecoverPage || isBusinessOnly}>
        Add Items
      </Menu.Item>
    </Menu>
  );
};
