import * as yup from 'yup';

export interface ProductXrefItemsParams {
  alternateItemIdContains?: string;
  alternateItemIds?: string; // API bug, should be singular not plural
  customerItemDescriptionContains?: string;
  defaultPurchasingVendorContains?: string;
  includeDeletedData?: boolean;
  includeInactiveData?: boolean;
  isRtu?: boolean;
  isActive?: boolean;
  offset: number;
  take: number;
  orderByDirection?: 'ascending' | 'descending';
  orderByField?: 'productNumber' | 'alternateItemId';
  productNumberContains?: string;
  productNumbers?: string[];
  returnDeletedDataOnly?: boolean;
  returnInactiveDataOnly?: boolean;
  isOverridden?: boolean;
  // For Url Purposes
}

export interface ProductXrefItemPayload {
  productNumber: string;
  alternateItemId: string;
  customerItemDescription?: string | null;
  customerStandardCost: number;
  isActive: boolean;
  defaultPurchasingVendor?: string | null;
  isRTU: boolean;
  isOverridden: boolean;
}

export enum ProductXrefItemLabels {
  category = 'Category',
  alternateItemIdLabel = 'Alt Item Id Label',
  alternateItemId = 'Alt Item Id',
  manufacturer = 'Manufacturer',
  customerStandardCost = 'Customer Standard Cost',
  customerItemDescription = 'Customer Item Description',
  productName = 'Product Name',
  productNumber = 'Product Number',
  isActive = 'Is Active',
  defaultPurchasingVendor = 'Default Purchasinging Vendor',
  isRtu = 'Is RTU',
  isOverridden = 'Is Overridden'
}

export const productXrefItemPayloadSchema: yup.SchemaOf<ProductXrefItemPayload> = yup.object({
  isRTU: yup.boolean().label(ProductXrefItemLabels.isRtu).required(),
  alternateItemId: yup.string().label(ProductXrefItemLabels.alternateItemId).required(),
  customerItemDescription: yup.string().label(ProductXrefItemLabels.customerItemDescription).notRequired(),
  productNumber: yup.string().label(ProductXrefItemLabels.productNumber).required(),
  customerStandardCost: yup.number().label(ProductXrefItemLabels.customerStandardCost).required(),
  defaultPurchasingVendor: yup.string().label(ProductXrefItemLabels.defaultPurchasingVendor).notRequired(),
  isActive: yup.boolean().label(ProductXrefItemLabels.isActive).required(),
  isOverridden: yup.boolean().label(ProductXrefItemLabels.isOverridden).required()
});

export const productXrefItemImportPayloadSchema: yup.SchemaOf<Omit<ProductXrefItemPayload, 'isActive' | 'isOverridden' | 'productName' | 'manufacturer' | 'category'>> = yup.object({
  isRTU: yup.boolean().label(ProductXrefItemLabels.isRtu).required(),
  alternateItemId: yup.string().label(ProductXrefItemLabels.alternateItemId).required(),
  customerItemDescription: yup.string().label(ProductXrefItemLabels.customerItemDescription).nullable(),
  productNumber: yup.string().label(ProductXrefItemLabels.productNumber).required(),
  customerStandardCost: yup.number().label(ProductXrefItemLabels.customerStandardCost).required(),
  defaultPurchasingVendor: yup.string().label(ProductXrefItemLabels.defaultPurchasingVendor).nullable(),
  isActive: yup.boolean().label(ProductXrefItemLabels.isActive).required()
});

export type AssignXrefForm = Omit<ProductXrefItemPayload, 'productName' | 'productNumber' | 'manufacturer' | 'category'>;

export const assignReferenceFormSchema: yup.SchemaOf<AssignXrefForm> = yup.object({
  isRTU: yup.boolean().label(ProductXrefItemLabels.isRtu).required(),
  alternateItemId: yup.string().label(ProductXrefItemLabels.alternateItemId).required(),
  customerItemDescription: yup.string().label(ProductXrefItemLabels.customerItemDescription).nullable(),
  customerStandardCost: yup.number().label(ProductXrefItemLabels.customerStandardCost).required(),
  defaultPurchasingVendor: yup.string().label(ProductXrefItemLabels.defaultPurchasingVendor).nullable(),
  isActive: yup.boolean().label(ProductXrefItemLabels.isActive).required(),
  isOverridden: yup.boolean().label(ProductXrefItemLabels.isOverridden).required()
});

export interface ProductXrefItem {
  alternateItemId: string;
  alternateItemIdLabel: string;
  auditMessages: string[];
  business: Business;
  category?: any;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  customerItemDescription: string | null;
  customerStandardCost: number;
  dataInheritanceLevel: string;
  defaultPurchasingVendor: string | null; //
  division?: Division;
  documentType: string;
  id: string;
  isDeleted: boolean;
  isOverridden: boolean;
  isRtu: boolean;
  isActive: boolean;
  manufacturer?: any;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  productName: string;
  productNumber: string;
}

interface Division {
  erpId: string;
  id: string;
  name: string;
}

interface Business {
  dataAreaId: string;
  erpId: string;
  id: string;
  name: string;
}
