import { BlockLoader } from 'components/atoms/BlockLoader';
import { RecoverRulesContextMenu } from 'components/atoms/RecoverRulesContextMenu';
import { AssignedRulesEditModal } from 'components/molecules/AssignedRulesEditModal';
import { AssignedRulesControls } from 'components/organisms/AssignedRulesControls';
import { RulesTable } from 'components/organisms/RulesTable';
import { ProductXrefItem } from 'models/ProductXref';
import React, { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDeletedProductXrefItemsQuery } from 'redux/services/floBear/floBearApi';
import { setOffset, setTake } from 'redux/services/floBear/floBearParams';
import { setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const RecoverRulesPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: customerId } = useParams();
  const dispatch = useAppDispatch();

  const { floBearProductXrefParams, assignedRules } = useAppSelector((state) => state);

  const {
    data: deletedProducts,
    isLoading,
    isFetching
  } = useGetDeletedProductXrefItemsQuery(
    { customerId: customerId as string, params: { ...floBearProductXrefParams, offset: floBearProductXrefParams.offset, take: floBearProductXrefParams.take } },
    { skip: !customerId }
  );

  const totalCount = deletedProducts?.totalCount || 0;

  const tableLoading = {
    spinning: isLoading || (isFetching && floBearProductXrefParams.offset === 0),
    indicator: <BlockLoader direction="loader loader--slideUp" />
  };

  /* ******************** Variables / Functions ******************** */
  const handleSelectRow = (selectedRowKeys: React.Key[], selectedRows: ProductXrefItem[]): void => {
    dispatch(setSelectedRules(selectedRows.map((item) => item.id)));
  };

  useEffect(() => (): any => dispatch(setSelectedRules([])), []);

  const handlePageChange = (page: number, pageSize: number): void => {
    dispatch(setTake(pageSize));
    dispatch(setOffset((page - 1) * pageSize));
  };

  return (
    <>
      <AssignedRulesControls />
      <RecoverRulesContextMenu>
        <RulesTable
          loading={tableLoading}
          dataSource={deletedProducts?.data}
          rowSelection={{ type: 'checkbox', onChange: handleSelectRow, selectedRowKeys: assignedRules.selectedRules }}
          onRow={(record): { onContextMenu: (event: MouseEvent) => void } => ({
            onContextMenu: (event): void => {
              event.preventDefault();
              dispatch(setSelectedRules([record.id]));
            }
          })}
          size="small"
          rowKey={(item): string => item.id}
          pagination={{ total: totalCount, defaultPageSize: floBearProductXrefParams.take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
        />
      </RecoverRulesContextMenu>
      <AssignedRulesEditModal />
    </>
  );
};
