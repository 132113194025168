import { Col, Form, Row } from 'antd';
import { FancyNumberInput } from 'components/atoms/Inputs/FancyNumberInput';
import { SwitchInput, TextInput } from 'components/UI/FormItems';
import { AssignedProductsFormLabels } from 'models/UpdateProductXref';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  isInheritanceBusiness: boolean;
  isOverridden?: boolean;
  isModal?: boolean;
}

export const AssignRulesForm: FC<Props> = ({ isInheritanceBusiness, isOverridden, isModal }) => {
  const { pathname } = useLocation();

  const isBusinessEdit = pathname.includes('business/');

  // const { id: customerId } = useParams();
  // const { data: xrefConfig } = useGetXrefConfigQuery({ customerId: customerId as string }, { skip: !customerId });

  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <TextInput disabled={(!isOverridden && !isBusinessEdit && !isModal) || isInheritanceBusiness} fieldName="alternateItemId" label={AssignedProductsFormLabels.alternateItemId} />
        </Col>
        <Col span={24}>
          <TextInput
            disabled={(!isOverridden && !isBusinessEdit && !isModal) || isInheritanceBusiness}
            fieldName="customerItemDescription"
            label={AssignedProductsFormLabels.customerItemDescription}
          />
        </Col>
        <Col span={24}>
          <TextInput
            disabled={(!isOverridden && !isBusinessEdit && !isModal) || isInheritanceBusiness}
            fieldName="defaultPurchasingVendor"
            label={AssignedProductsFormLabels.defaultPurchasingVendor}
          />
        </Col>
        <Col span={24}>
          {/* <CurrencyInput fieldName="customerStandardCost" label={AssignedProductsFormLabels.customerStandardCost} /> */}
          <FancyNumberInput isDisabled={(!isOverridden && !isBusinessEdit && !isModal) || isInheritanceBusiness} isCurrency fieldName="customerStandardCost" placeHolder="Customer Standard Cost" />
        </Col>
        <Col span={24}>
          <SwitchInput
            disabled={(!isOverridden && !isBusinessEdit && !isModal) || isInheritanceBusiness}
            fieldName="isActive"
            label={AssignedProductsFormLabels.isActive}
            labelPosition="top"
            isInheritanceBusiness={isInheritanceBusiness}
          />
        </Col>
        <Col span={24}>
          <SwitchInput
            disabled={(!isOverridden && !isBusinessEdit && !isModal) || isInheritanceBusiness}
            fieldName="isRTU"
            label={AssignedProductsFormLabels.isRTU}
            isInheritanceBusiness={isInheritanceBusiness}
            labelPosition="top"
          />
        </Col>
      </Row>
    </Form>
  );
};
