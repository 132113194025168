import { Switch, SwitchProps, Typography } from 'antd';
import { useField } from 'formik';
import React, { FC } from 'react';
import { Stack } from '../Stack';
interface Props extends SwitchProps {
  fieldName: string;
  label?: string;
  labelPosition?: 'top' | 'right';
  isInheritanceBusiness: boolean;
}

export const SwitchInput: FC<Props> = ({ fieldName, label, labelPosition, isInheritanceBusiness, ...rest }) => {
  const [{ value }, meta, { setValue }] = useField<boolean>(fieldName);

  return (
    <Stack flexDirection={labelPosition === 'top' ? 'column-reverse' : 'row'} alignItems="flex-start">
      <Switch checked={value} onChange={(checked): void => setValue(checked)} disabled={isInheritanceBusiness || rest.disabled} />
      {!!label && <Typography.Text>{label}</Typography.Text>}
    </Stack>
  );
};
