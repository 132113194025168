import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Spin, SpinProps, Tooltip } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { productXrefItemImportPayloadSchema, ProductXrefItemPayload } from 'models/ProductXref';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { addErrorItem, setImportItems, setImportTotal } from 'redux/slices/importSlice';
import { useAppDispatch } from 'redux/store';
import { validatedHeaderRow } from 'utils/validation';
import * as xlsx from 'xlsx';
import { ValidationError } from 'yup';

const headers = ['productNumber', 'alternateItemId', 'customerItemDescription', 'defaultPurchasingVendor', 'customerStandardCost', 'isRTU', 'isActive'];

export const ImportModal: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { pathname } = useLocation();

  const isBusinessEdit = pathname.includes('business/');

  const [isOpen, _setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isLoading, _setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { data: divisionXrefConfig } = useGetXrefConfigQuery({ customerId: entityId as string }, { skip: !entityId });

  /* ****************** Variables ****************** */
  const isBusinessOnly = divisionXrefConfig?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');
  const importLoadingConfig: SpinProps = {
    indicator: (
      <div style={{ width: 50, marginLeft: -25 }}>
        <BlockLoader direction="loader loader--slideUp" />
      </div>
    ),
    spinning: isLoading,
    wrapperClassName: isLoading ? 'list-loading' : ''
  };

  /* ******************** Functions ******************** */
  const onOpen = (): void => _setIsOpen(true);
  const onClose = (): void => _setIsOpen(false);

  const handleImport = async (file: RcFile | Blob): Promise<void> => {
    if (!entityId) {
      message.error('No entity Id!');

      return;
    }

    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error('You can only import files with the extension .xlsx');

      return;
    }

    _setIsLoading(true);
    const buffer = await file.arrayBuffer();
    const workbook = xlsx.read(buffer, { type: 'buffer' });

    for (const sheetName of workbook.SheetNames) {
      const sheetRows = xlsx.utils.sheet_to_json<ProductXrefItemPayload>(workbook.Sheets[sheetName], { header: headers });

      const isHeaderRowValid = !!sheetRows.length && validatedHeaderRow(sheetRows[0]);

      if (isHeaderRowValid && isHeaderRowValid.length) {
        message.error(JSON.stringify(isHeaderRowValid), 8);
        _setIsLoading(false);

        return;
      }

      const itemsToCreate: ProductXrefItemPayload[] = [];

      const adjustedSheetRow = sheetRows.slice(1).map((row) => {
        if (!Object.keys(sheetRows.slice(1)).some((row) => row === 'defaultPurchasingVendor')) return { ...row, defaultPurchasingVendor: '' };
        if (!Object.keys(sheetRows.slice(1)).some((row) => row === 'customerItemDescription')) return { ...row, customerItemDescription: '' };
        else return row;
      });

      for (const sheetRow of adjustedSheetRow) {
        try {
          await productXrefItemImportPayloadSchema.validate(sheetRow);
          itemsToCreate.push(sheetRow);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (error instanceof ValidationError) {
            dispatch(addErrorItem({ productNumber: sheetRow.productNumber, errors: error.errors }));
          } else if ('data' in error) {
            dispatch(addErrorItem({ productNumber: sheetRow.productNumber, errors: [error.data] }));
          } else {
            dispatch(addErrorItem({ productNumber: sheetRow.productNumber, errors: [error.message] }));
          }
        }
      }

      dispatch(setImportTotal(adjustedSheetRow.length));
      dispatch(setImportItems(itemsToCreate));
      _setIsLoading(false);
      navigate(`/${isBusinessEdit ? 'business' : 'division'}/${entityId}/import`);
    }
  };

  return (
    <>
      <ButtonGroup>
        <Button onClick={onOpen} disabled={isBusinessOnly}>
          Import
        </Button>
        <Tooltip title="Download import template">
          <Button download href={process.env.REACT_APP_CUSTOMER_PRODUCT_XREF_IMPORT_TEMPLATE}>
            <DownloadOutlined />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Modal visible={isOpen} onCancel={onClose} title="Import" footer={[]}>
        <Spin spinning={isLoading} indicator={importLoadingConfig.indicator} tip={`Preparing Import`}>
          <Dragger
            showUploadList={false}
            customRequest={({ file }): void => {
              if (typeof file !== 'string') handleImport(file);
            }}>
            Upload (xlsx)
          </Dragger>
        </Spin>
      </Modal>
    </>
  );
};
