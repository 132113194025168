import { List, message } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { BusinessListItem } from 'components/molecules/BusinessListItem';
import { Business } from 'models/Business';
import { Division } from 'models/Division';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useLazyGetXrefConfigsQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setCachedBusinessList, setCachedCatalogConfig } from 'redux/slices/businessListSlice';
import { useAppSelector } from 'redux/store';

export const BusinessList = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { inheritanceTypeFilter, customerSearch, cachedBusinessList, cachedCatalogConfig } = useAppSelector((state) => state.businessList);
  const { isTriggerLoading } = useAppSelector((state) => state.app);

  const [getBussinessLazy, { isFetching, isLoading }] = useLazyGetBusinessesQuery();

  const [getLazyCatalogConfigs, { isLoading: isConfigLoading, isFetching: isConfigFetching }] = useLazyGetXrefConfigsQuery();

  const dispatch = useDispatch();

  const isDocumentLoading = Boolean(isLoading || isFetching || isConfigFetching || isConfigLoading || isTriggerLoading);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        const response = await getBussinessLazy({ includeDivisions: true }).unwrap();
        const configResponse = await getLazyCatalogConfigs({ dataInheritanceLevels: 'Business,Division,BusinessWithDivisionOverride' }).unwrap();

        dispatch(setCachedBusinessList(response.data));
        dispatch(setCachedCatalogConfig(configResponse?.data));
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    };

    if (!cachedBusinessList || !cachedCatalogConfig) fetchData();
  }, [cachedBusinessList, cachedCatalogConfig]);

  /* ******************** Functions / Variables ******************** */
  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (cachedBusinessList && cachedCatalogConfig) {
      for (const business of cachedBusinessList) {
        // Map the divisions
        let fullDivisions: Division[] = [];

        for (const division of business.divisions) {
          const catalogConfig = cachedCatalogConfig.find((item) => item.division?.id === division.id);

          // If full division is found
          if (catalogConfig) {
            if (!inheritanceTypeFilter.length) {
              // If no filters return the division
              fullDivisions = [...fullDivisions];
            } else {
              // If filters exists
              // If business type
              if (catalogConfig.dataInheritanceLevel === 'Business' && inheritanceTypeFilter.includes('Business')) {
                const updatedDivision = { ...division, ...{ productCatalogConfiguration: { dataInheritanceLevel: catalogConfig.dataInheritanceLevel } } };

                fullDivisions = [...fullDivisions, ...[updatedDivision]];
              }
              // if division type
              if (catalogConfig.dataInheritanceLevel === 'Division' && inheritanceTypeFilter.includes('Division')) {
                const updatedDivision = { ...division, ...{ productCatalogConfiguration: { dataInheritanceLevel: catalogConfig.dataInheritanceLevel } } };

                fullDivisions = [...fullDivisions, ...[updatedDivision]];
              }
              // if override type
              if (catalogConfig.dataInheritanceLevel === 'BusinessWithDivisionOverride' && inheritanceTypeFilter.includes('BusinessWithDivisionOverride')) {
                const updatedDivision = { ...division, ...{ productCatalogConfiguration: { dataInheritanceLevel: catalogConfig.dataInheritanceLevel } } };

                fullDivisions = [...fullDivisions, ...[updatedDivision]];
              }
            }
          } else {
            fullDivisions = [...fullDivisions, ...[division]];
          }
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [cachedBusinessList, customerSearch, cachedCatalogConfig, inheritanceTypeFilter]);

  /* ******************** Render ******************** */
  return (
    <List
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isDocumentLoading,
        wrapperClassName: isDocumentLoading ? 'list-loading' : ''
      }}
      dataSource={filteredData}
      renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
      rowKey={(item): string => item.id}
    />
  );
};
