import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductXrefItemsParams } from 'models/ProductXref';

const initialState: ProductXrefItemsParams = {
  alternateItemIdContains: undefined,
  alternateItemIds: undefined,
  customerItemDescriptionContains: undefined,
  defaultPurchasingVendorContains: undefined,
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  isActive: undefined,
  isOverridden: undefined,
  isRtu: undefined,
  offset: 0,
  orderByDirection: 'ascending',
  orderByField: undefined,
  productNumberContains: undefined,
  productNumbers: undefined,
  returnDeletedDataOnly: undefined,
  returnInactiveDataOnly: undefined,
  take: 25
};

export const floBearProductXrefParams = createSlice({
  name: 'floBearProductXrefParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<ProductXrefItemsParams['offset']>) => {
      state.offset = payload;
    },
    setTake: (state, { payload }: PayloadAction<ProductXrefItemsParams['take']>) => {
      state.take = payload;
      state.offset = 0;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<ProductXrefItemsParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
      state.offset = 0;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<ProductXrefItemsParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
      state.offset = 0;
    },
    setIncludeDeletedData: (state, { payload }: PayloadAction<ProductXrefItemsParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
      state.offset = 0;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<ProductXrefItemsParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
      state.offset = 0;
    },
    setProductNumberContains: (state, { payload }: PayloadAction<ProductXrefItemsParams['productNumberContains']>) => {
      state.productNumberContains = payload;
      state.offset = 0;
    },
    setProductNumbers: (state, { payload }: PayloadAction<ProductXrefItemsParams['productNumbers']>) => {
      state.productNumbers = payload;
      state.offset = 0;
    },
    setAlternateItemIdContains: (state, { payload }: PayloadAction<ProductXrefItemsParams['alternateItemIdContains']>) => {
      state.alternateItemIdContains = payload;
      state.offset = 0;
    },
    setAlternateItemIds: (state, { payload }: PayloadAction<ProductXrefItemsParams['alternateItemIds']>) => {
      state.alternateItemIds = payload;
      state.offset = 0;
    },
    setCustomerItemDescriptionContains: (state, { payload }: PayloadAction<ProductXrefItemsParams['customerItemDescriptionContains']>) => {
      state.customerItemDescriptionContains = payload;
      state.offset = 0;
    },
    setDefaultPurchasingVendorContains: (state, { payload }: PayloadAction<ProductXrefItemsParams['defaultPurchasingVendorContains']>) => {
      state.defaultPurchasingVendorContains = payload;
      state.offset = 0;
    },
    setIsRtu: (state, { payload }: PayloadAction<ProductXrefItemsParams['isRtu']>) => {
      state.isRtu = payload;
      state.offset = 0;
    },
    setIsActive: (state, { payload }: PayloadAction<ProductXrefItemsParams['isActive']>) => {
      state.isActive = payload;
      state.offset = 0;
    },
    setIsOverridden: (state, { payload }: PayloadAction<ProductXrefItemsParams['isOverridden']>) => {
      state.isOverridden = payload;
      state.offset = 0;
    },
    resetParams(state) {
      Object.assign(state, initialState);
    }
  }
});

export const {
  resetParams,
  setAlternateItemIdContains,
  setAlternateItemIds,
  setCustomerItemDescriptionContains,
  setDefaultPurchasingVendorContains,
  setIncludeDeletedData,
  setIncludeInactiveData,
  setIsActive,
  setIsOverridden,
  setIsRtu,
  setOffset,
  setProductNumberContains,
  setProductNumbers,
  setReturnDeletedDataOnly,
  setReturnInactiveDataOnly,
  setTake
} = floBearProductXrefParams.actions;
