import React, { useEffect } from 'react';
import { setCustomerItemDescriptionContains } from 'redux/services/floBear/floBearParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const CustomerItemDescriptionFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setCustomerItemDescriptionContains(value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setCustomerItemDescriptionContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput width={220} placeholder="Customer Item Description" onChange={handleChange} />;
};
