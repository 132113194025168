import { Button, message, Modal, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { usePurgeProductXrefItemMutation, useRecoverProductXrefItemMutation } from 'redux/services/floBear/floBearApi';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppSelector } from 'redux/store';

export const RecoverRulesActions = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { pathname } = useLocation();

  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [purgeCount, _setPurgeCount] = useState(1);
  const dispatch = useDispatch();

  const {
    assignedRules: { selectedRules }
  } = useAppSelector((state) => state);

  const { data: catalogConfiguration } = useGetXrefConfigQuery({ customerId: entityId as string });
  const [recoverXrefItem, { isLoading }] = useRecoverProductXrefItemMutation();
  const [purgeCatalogItem, { isLoading: isPurgingCatalogItems }] = usePurgeProductXrefItemMutation();

  /* ******************** Functions / Variables ******************** */
  const totalSelectedRules = selectedRules.length;
  const isBusinessInheritance = catalogConfiguration?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');

  const handleRestoreSelectedRoles = async (): Promise<void> => {
    if (!entityId) {
      message.error('No entity selected!');

      return;
    }

    for (const productId of selectedRules) {
      try {
        await recoverXrefItem({ customerId: entityId, productId }).unwrap();
      } catch {
        message.error(`Business product xref item ${productId} failed to be recovered!`);
      }
    }

    message.success(`Business product xref item(s) were successfully recovered!`);
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (!entityId) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const productId of selectedRules) {
      try {
        await purgeCatalogItem({ customerId: entityId, productId }).unwrap();
        _setPurgeCount((prev) => prev + 1);
      } catch (error) {
        message.error(`Failed to delete product catalog item ${productId}`);
      }
    }

    _setPurgeCount(1);
    message.success(`Purged product catalog item(s)!`);
    _setShowPurgeModal(false);
    dispatch(setSelectedRules([]));
  };

  return (
    <Space>
      <Button onClick={handleRestoreSelectedRoles} type="primary" loading={isLoading} disabled={!totalSelectedRules || isBusinessInheritance}>
        Restore Selected ({totalSelectedRules})
      </Button>
      <Button onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isLoading} disabled={!totalSelectedRules || isBusinessInheritance}>
        Purge Selected ({totalSelectedRules})
      </Button>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedRules})`}
        visible={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isPurgingCatalogItems}
        maskClosable={!isPurgingCatalogItems}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isPurgingCatalogItems ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isPurgingCatalogItems ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isPurgingCatalogItems}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-12%' }}>
              <BlockLoader direction="loader loader--slideUp" style={{ marginBottom: 30 }} />
              <Typography.Text>{`Purging Catalog Item ${purgeCount} of ${totalSelectedRules}...`}</Typography.Text>
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED XREF ITEMS.</Typography.Text>
        </Spin>
      </Modal>
    </Space>
  );
};
