import { SettingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType, ColumnType, TableProps } from 'antd/lib/table';
import { OverridenSwitch } from 'components/atoms/OverridenSwitch';
import { ProductXrefItem } from 'models/ProductXref';
import { useLocation, useParams } from 'react-router-dom';
import { useCreateXrefConfigMutation, useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { formatCurrency } from 'utils/formatters';
const { Text, Link } = Typography;

interface Props extends TableProps<ProductXrefItem> {
  onProductNumber?: (productNumber: string) => void;
}

export const RulesTable = ({ onProductNumber, ...rest }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const { id: customerId } = useParams();
  const isBusinessView = pathname.includes('business/');
  const { data: xrefConfig } = useGetXrefConfigQuery({ customerId: customerId as string }, { skip: !customerId });
  const [updateAltItemIdLabel] = useCreateXrefConfigMutation();

  const [form] = Form.useForm();

  const handleAlternateIdLabelChange = (): void => {
    Modal.confirm({
      title: 'Rename Alternate Item Id Label',
      content: (
        <Form layout="vertical" form={form}>
          <Form.Item label="New Alternate Id Label" name="alternateItemIdLabel">
            <Input />
          </Form.Item>
        </Form>
      ),
      onOk: () => {
        if (!xrefConfig) {
          message.error('Unable to save changes. Please try again');

          return;
        }

        updateAltItemIdLabel({
          customerId: customerId as string,
          payload: { dataInheritanceLevel: xrefConfig.dataInheritanceLevel, alternateItemIdLabel: form.getFieldValue('alternateItemIdLabel') }
        });
      }
    });
  };

  const columns: ColumnsType<ProductXrefItem> = [
    {
      dataIndex: 'productNumber',
      title: (
        <Tooltip title={'This is the unique identifier of the released product in D365.'}>
          <Text ellipsis strong>
            Product Number
          </Text>
        </Tooltip>
      ),
      key: 'productNumber',
      width: '30ch',
      fixed: true,
      render: onProductNumber ? (value, record): JSX.Element => <Link onClick={(): void => onProductNumber(record.id)}>{value}</Link> : undefined
    },
    {
      dataIndex: 'alternateItemId',
      title: (
        <Space>
          <Tooltip title={"This is the customer's unique identifier of the product in their ERP. Any changes made here will be syncrhonized to the productCatalog."}>
            <Text ellipsis strong>
              {xrefConfig?.alternateItemIdLabel ?? 'Alternate Item Id'}
            </Text>
          </Tooltip>
          {isBusinessView && (
            <Tooltip title="Rename Alternate Item Id Label" mouseEnterDelay={1}>
              <Button onClick={handleAlternateIdLabelChange} size="small" type="ghost" icon={<SettingOutlined />} />
            </Tooltip>
          )}
        </Space>
      ),
      key: 'alternateItemId',
      width: '30ch'
    },
    {
      dataIndex: 'isRtu',
      title: (
        <Tooltip title={''}>
          <Text ellipsis strong>
            Is RTU
          </Text>
        </Tooltip>
      ),
      key: 'isRtu',
      width: '14ch',
      render: (value: boolean): string => (value ? 'TRUE' : 'FALSE')
    },
    {
      dataIndex: 'isActive',
      title: (
        <Tooltip title={'This indicates wheter or not the record is active and in use by other systems.'}>
          <Text ellipsis strong>
            Is Active
          </Text>
        </Tooltip>
      ),
      key: 'isActive',
      width: '14ch',
      render: (value: boolean): string => (value ? 'TRUE' : 'FALSE')
    },
    {
      dataIndex: 'customerItemDescription',
      title: (
        <Tooltip title={"This is the customer's description of the part."}>
          <Text ellipsis strong>
            Customer Item Description
          </Text>
        </Tooltip>
      ),
      key: 'customerItemDescription'
    },
    {
      dataIndex: 'defaultPurchasingVendor',
      title: (
        <Tooltip title={'This is the default vendor from which to purchase this part.'}>
          <Text ellipsis strong>
            Default Purchasing Vendor
          </Text>
        </Tooltip>
      ),
      key: 'defaultPurchasingVendor'
    },
    {
      dataIndex: 'customerStandardCost',
      title: (
        <Tooltip title={"This is the customer's standard cost of the part."}>
          <Text ellipsis strong>
            Customer Standard Cost
          </Text>
        </Tooltip>
      ),
      key: 'customerStandardCost',
      width: '25ch',
      render: formatCurrency,
      align: 'right'
    }
  ];

  const isOverriddenConfig: ColumnType<ProductXrefItem> = {
    dataIndex: 'isOverridden',
    title: (
      <Tooltip
        title={
          'Indicates whether or not the customerXRefItem has been set at the division level and is overriding the business customerXRefItem. This can only be true if the inheritance level is set to Division or BusinessWithDivisionOverride.'
        }>
        <Text ellipsis strong>
          Is Overridden
        </Text>
      </Tooltip>
    ),
    key: 'isOverridden',
    width: '10ch',
    render: (value, record): JSX.Element | undefined => (pathname.includes('division') ? <OverridenSwitch item={record} /> : undefined)
  };

  if (pathname.includes('division') && xrefConfig?.dataInheritanceLevel !== 'Business' && xrefConfig?.dataInheritanceLevel !== 'Division') columns.splice(1, 0, isOverriddenConfig);

  return <Table columns={columns} size="small" rowKey={(item): string => item.productNumber} {...rest} />;
};
