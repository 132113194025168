import { message, Modal, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FormikProvider, useFormik } from 'formik';
import { assignReferenceFormSchema, AssignXrefForm } from 'models/ProductXref';
import { useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useCreateProductXrefItemMutation } from 'redux/services/floBear/floBearApi';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setSelectedProducts } from 'redux/slices/productSearchSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { AssignRulesForm } from './AssignRulesForm';

export const AssignProductsModal = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();
  const { pathname } = useLocation();
  const isBusinessEdit = pathname.includes('business/');
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const [creationCount, setCreationCount] = useState(1);

  const { selectedProducts } = useAppSelector((state) => state.productSearch);

  const [createXrefItem] = useCreateProductXrefItemMutation();

  const { data: xrefConfigData } = useGetXrefConfigQuery({ customerId: entityId as string }, { skip: !entityId || isBusinessEdit });

  /* ******************** Functions / Variables ******************** */
  const assignIdsParamValue = searchParams.get('assignIds');
  const assignIds = assignIdsParamValue ? assignIdsParamValue.split(',') : [];
  const isOpen = Boolean(assignIdsParamValue);

  const handleClose = (): void => {
    searchParams.delete('assignIds');
    setSearchParams(searchParams);
  };

  const handleAssignProducts = async (values: AssignXrefForm): Promise<void> => {
    if (!entityId) {
      message.error('Entity Id is missing!');

      return;
    }

    for (const selectedProduct of selectedProducts) {
      try {
        await createXrefItem({
          customerId: entityId,
          payload: {
            ...values,
            productNumber: selectedProduct.productNumber
          }
        }).unwrap();
        setCreationCount((prev) => prev + 1);
      } catch {
        message.error('Product xref item failed to be created!');
      }
    }

    setCreationCount(1);
    message.success(`CustomerXref Items were successfully created!`);
    dispatch(setSelectedProducts([]));
    handleClose();
  };

  const formik = useFormik<AssignXrefForm>({
    validationSchema: assignReferenceFormSchema,
    initialValues: {
      alternateItemId: '',
      customerItemDescription: '',
      defaultPurchasingVendor: '',
      isRTU: false,
      customerStandardCost: 0,
      isOverridden: isBusinessEdit ? false : xrefConfigData?.dataInheritanceLevel !== 'Business' ? true : false,
      isActive: true
    },
    onSubmit: handleAssignProducts
  });

  /* ******************** Renderer ******************** */
  return (
    <Modal
      title={`Assign Selected Product (${assignIds.length})`}
      visible={isOpen}
      closable={!formik.isSubmitting}
      afterClose={(): void => formik.resetForm()}
      cancelButtonProps={formik.isSubmitting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
      okButtonProps={formik.isSubmitting ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
      onOk={(): void => formik.handleSubmit()}
      onCancel={!formik.isSubmitting ? handleClose : undefined}
      okText="Assign"
      confirmLoading={formik.isSubmitting}>
      <FormikProvider value={formik}>
        <Spin
          spinning={formik.isSubmitting}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-20%' }}>
              <BlockLoader direction="loader loader--slideUp" style={{ marginBottom: 30 }} />
              <Typography.Text>{`Creating Xref Item ${creationCount} of ${selectedProducts.length}...`}</Typography.Text>
            </Space>
          }>
          <AssignRulesForm isModal={isOpen} isInheritanceBusiness={false} />
        </Spin>
      </FormikProvider>
    </Modal>
  );
};
