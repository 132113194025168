import * as yup from 'yup';
import { AssignXrefForm } from './ProductXref';

export type AssignedProductsUpdateForm = Partial<AssignXrefForm>;

export const assignedProductsUpdateFormSchema: yup.SchemaOf<AssignedProductsUpdateForm> = yup.object({
  alternateItemId: yup.string(),
  customerItemDescription: yup.string(),
  defaultPurchasingVendor: yup.string(),
  isRTU: yup.boolean(),
  customerStandardCost: yup.number(),
  isOverridden: yup.boolean(),
  isActive: yup.boolean()
});

export const AssignedProductsFormLabels: Record<keyof AssignedProductsUpdateForm, string> = {
  alternateItemId: 'Alternate Item Id',
  customerItemDescription: 'Customer Item Description',
  defaultPurchasingVendor: 'Default Purchasing Vendor',
  isRTU: 'Is RTU',
  customerStandardCost: 'Customer Standard Cost',
  isOverridden: 'Is Overridden',
  isActive: 'Is Active'
} as const;
