import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setAlternateItemIdContains } from 'redux/services/floBear/floBearParams';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const AlternateItemIdFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: customerId } = useParams();

  const { data: xrefConfig, isLoading, isFetching } = useGetXrefConfigQuery({ customerId: customerId as string }, { skip: !customerId });

  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setAlternateItemIdContains(value));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setAlternateItemIdContains(undefined));
    };
  }, [dispatch]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <FancyInput placeholder={xrefConfig?.alternateItemIdLabel} onChange={handleChange} />
    </Spin>
  );
};
