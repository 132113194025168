import { useSearchParams } from 'react-router-dom';

interface XrefParams {
  productNumberContains: string | undefined;
  alternateItemIdContains: string | undefined;
  includeInactiveData: boolean | undefined;
  returnInactiveDataOnly: boolean | undefined;
  isRtu: boolean | undefined;
  customerItemDescriptionContains: string | undefined;
  defaultPurchasingVendorContains: string | undefined;
}

export const useGetXRefParams = (): XrefParams => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleBooleanEval = (queryParam: string): boolean | undefined => {
    const value = searchParams.get(queryParam);

    switch (value) {
      case 'true':
        return true;

      case 'false':
        return false;

      default:
        return undefined;
    }
  };

  const params = {
    productNumberContains: searchParams.get('productNumberContains') || undefined,
    alternateItemIdContains: searchParams.get('alternateItemIdContains') || undefined,
    includeInactiveData: handleBooleanEval('includeInactiveData'),
    returnInactiveDataOnly: handleBooleanEval('returnInactiveDataOnly'),
    isRtu: handleBooleanEval('isRtu'),
    customerItemDescriptionContains: searchParams.get('customerItemDescriptionContains') || undefined,
    defaultPurchasingVendorContains: searchParams.get('defaultPurchasingVendorContains') || undefined
  };

  // console.log(params, '<<<<< params');

  return params;
};
