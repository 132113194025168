import { Form } from 'antd';
import { FormLabel } from 'components/UI/FormItems/FormLabel';
import { useField } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';

interface FancyNumberInputProps {
  placeHolder: string;
  fieldName: string;
  isCurrency: boolean;
  isDisabled: boolean;
}

const styles: InlineStylesModel = {
  borderAnimation: {
    // transform: 'translateX(-100%)',
    transition: 'all 0.3s ease'
  },
  input: {
    width: '100%',
    minWidth: 125
  },
  labelUnfocused: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.50)',
    top: 5,
    left: 10,
    zIndex: 2,
    transition: 'all 0.3s ease'
  },
  labelFocused: {
    backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(bottom white 50%, lightblue 50%)',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: 2,
    top: -15,
    left: 8,
    transition: 'all 0.3s ease'
  },
  disabled: {
    cursor: 'not-allowed'
  },
  numberInput: {
    padding: 5,
    width: '100%'
  },
  titleColor: {
    color: '#2F5596'
  }
};

export const FancyNumberInput: FC<FancyNumberInputProps> = ({ placeHolder, isCurrency, fieldName, isDisabled, ...rest }: FancyNumberInputProps) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<number>(fieldName);

  const handleChange = (e: NumberFormatValues): void => {
    if (e.floatValue) {
      setValue(e.floatValue);
    }
  };

  return (
    <Form.Item help={touched && error ? error : undefined} style={styles.form}>
      <FormLabel label={placeHolder} />
      <NumericFormat
        className="ant-input-number"
        thousandSeparator={true}
        onValueChange={handleChange}
        disabled={isDisabled}
        value={value || undefined}
        prefix={isCurrency ? '$' : undefined}
        decimalScale={isCurrency ? 2 : 0}
        fixedDecimalScale={isCurrency}
        onFocus={(): void => setTouched(false)}
        onBlur={(): void => setTouched(true)}
        style={styles.numberInput}
        allowLeadingZeros={false}
        allowNegative={false}
        {...rest}
      />
    </Form.Item>
  );
};
