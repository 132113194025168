import { message, Space } from 'antd';
import { AntButton } from 'components/UI/AntButton';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setOffset } from 'redux/services/donaldGrump/releasedProductsParams';
import { resetParams as resetSearchXrefParams } from 'redux/services/floBear/floBearParams';
import { setSelectedProducts } from 'redux/slices/productSearchSlice';
import { ReduxState, useAppDispatch, useAppSelector } from 'redux/store';
import { BulkSearchModal } from './BulkSearchModal';

export const BulkSearchActions: React.FC = () => {
  /* ******************** Hooks ******************** */
  const {
    donaldGrumpReleasedProductsParams,
    productSearch: { selectedProducts }
  } = useAppSelector((state) => state);

  const { bulkSearchedProducts } = useAppSelector((state: ReduxState) => state.productSearch);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  /* ******************** Functions / Variables ******************** */
  const totalSelectedProducts = selectedProducts.length;
  const isBusinessEdit = pathname.includes('business/');

  const handleSelectAllProducts = (): void => {
    if (bulkSearchedProducts) {
      dispatch(setSelectedProducts(bulkSearchedProducts));
    } else {
      message.error('Nothing to select!');
    }
  };

  const handleDeselectAllProducts = (): void => {
    dispatch(setSelectedProducts([]));
  };

  const handleAssignSelectedProducts = (): void => {
    searchParams.append('assignIds', selectedProducts.map((item) => item.productNumber).join(','));
    setSearchParams(searchParams);
  };

  const handleStandardSearch = (): void => {
    navigate(`/${isBusinessEdit ? 'business' : 'division'}/${id}/search`);
    dispatch(resetSearchXrefParams());
  };

  useEffect(
    () => (): void => {
      dispatch(setSelectedProducts([]));
      dispatch(setOffset(0));
    },
    [dispatch]
  );

  return (
    <Space>
      <AntButton onClick={handleDeselectAllProducts} type="ghost" danger disabled={!totalSelectedProducts}>
        Deselect All ({totalSelectedProducts})
      </AntButton>
      <AntButton onClick={handleSelectAllProducts} type="primary" disabled={false}>
        Select All Bulk Results
      </AntButton>
      <AntButton onClick={handleAssignSelectedProducts} type="primary" success="true" disabled={!totalSelectedProducts}>
        Assign Selected ({totalSelectedProducts})
      </AntButton>
      <BulkSearchModal />
      <AntButton onClick={handleStandardSearch} type="primary" ghost success="true">
        Standard Search
      </AntButton>
    </Space>
  );
};
