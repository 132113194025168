import { TableRowSelection } from 'antd/lib/table/interface';
import { AssignedRulesContextMenu } from 'components/atoms/AssignedRulesContextMenu';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CreateInheritanceConfigModal } from 'components/atoms/CreateInheritanceConfigModal';
import { JobsInProcess } from 'components/atoms/JobsInProcess';
import { AssignedRulesEditModal } from 'components/molecules/AssignedRulesEditModal';
import { AssignedRulesControls } from 'components/organisms/AssignedRulesControls';
import { RulesTable } from 'components/organisms/RulesTable';
import { ProductXrefItem } from 'models/ProductXref';
import { RenderedCell } from 'rc-table/lib/interface';
import React, { MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetProductXrefItemsQuery, useGetXrefJobsQuery } from 'redux/services/floBear/floBearApi';
import { setOffset, setTake } from 'redux/services/floBear/floBearParams';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { addSelectedRule, setSelectedRules } from 'redux/slices/assignedRulesSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const AssignedRulesPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id: entityId } = useParams();

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isBusinessEdit = pathname.includes('business/');

  const { floBearProductXrefParams, assignedRules, rudyCadabbyApi } = useAppSelector((state) => state);

  const [isPollingJob, _setIsPollingJobStarted] = useState(false);

  /* ****************** API Hooks ****************** */
  const {
    data: productXrefItemsResponse,
    isLoading: isResponseLoading,
    isFetching: isResponseFetching,
    isError,
    error,
    refetch
  } = useGetProductXrefItemsQuery(
    { customerId: entityId as string, params: { ...floBearProductXrefParams, offset: floBearProductXrefParams.offset, take: floBearProductXrefParams.take } },
    { skip: !entityId }
  );

  const { data: xrefConfig, isLoading: isXrefConfigLoading, isError: isXrefConfigError } = useGetXrefConfigQuery({ customerId: entityId as string }, { skip: !entityId });

  // Check to see if there are any jobs behinde the scenes and prevent user to use the app until its finished
  const { data: xrefJobsData, isLoading: isXrefJobsLoading } = useGetXrefJobsQuery(
    { divisionId: entityId as string },
    { skip: isBusinessEdit || !entityId || isXrefConfigLoading || isXrefConfigError, pollingInterval: 2000 }
  );

  /* ******************** Variables ******************** */
  const isLoading = isResponseLoading || isXrefJobsLoading;
  const isFetching = isResponseFetching;
  const data = productXrefItemsResponse?.data;
  const totalCount = productXrefItemsResponse?.totalCount || 0;
  // const isNoInheritanceSelected = xrefConfig?.dataInheritanceLevel === undefined;
  const isBusinessOnly = xrefConfig?.dataInheritanceLevel === 'Business' && pathname.includes('/division/');
  const selectedKeys =
    assignedRules.selectedRules.length && productXrefItemsResponse ? assignedRules.selectedRules.map((id) => productXrefItemsResponse.data.find((item) => item.id === id)?.id ?? '') : [];

  /* ****************** Functions ****************** */

  const handleUpdateRecord = (productId: string): void => {
    navigate(`rules/${productId}`);
  };

  const handleBusinessSelectRow = (selectedRowKeys: React.Key[], selectedRows: ProductXrefItem[]): void => {
    dispatch(setSelectedRules(selectedRows.map((item) => item.id)));
  };

  const handleDivisionSelectRow = (selectedRowKeys: React.Key[], selectedRows: ProductXrefItem[]): void => {
    // Handle deslect all
    if (selectedKeys.length === selectedRows.filter((item) => item.isOverridden).map((item) => item.id).length) {
      dispatch(setSelectedRules([]));

      return;
    }

    // Handle all the rest
    dispatch(setSelectedRules(selectedRows.filter((item) => item.isOverridden).map((item) => item.id)));
  };

  const handlePageChange = (page: number, pageSize: number): void => {
    dispatch(setTake(pageSize));
    dispatch(setOffset((page - 1) * pageSize));
  };

  const handleTableRightClick = (idNumber: string): void => {
    if (assignedRules.selectedRules.includes(idNumber) === false) dispatch(addSelectedRule(idNumber));
  };

  const checkedCellJSX = (checked: boolean, record: ProductXrefItem, index: number, originalNode: React.ReactNode): React.ReactNode | RenderedCell<ProductXrefItem> | undefined => {
    if (!record.isOverridden && !isBusinessEdit) return <></>;

    return originalNode;
  };

  const tableLoading = {
    spinning: isLoading || (isFetching && floBearProductXrefParams.offset === 0),
    indicator: <BlockLoader direction="loader loader--slideUp" />
  };

  const rowSelectionConfig: TableRowSelection<ProductXrefItem> = {
    type: 'checkbox',
    onChange: isBusinessEdit ? handleBusinessSelectRow : handleDivisionSelectRow,
    renderCell: checkedCellJSX,
    selectedRowKeys: selectedKeys
  };

  useEffect(() => {
    if (!isPollingJob && !isXrefConfigError && !isXrefConfigLoading && xrefJobsData && xrefJobsData.length > 0) _setIsPollingJobStarted(true);
    if (isPollingJob && xrefJobsData?.length === 0) {
      _setIsPollingJobStarted(false);
      refetch();
    }
  }, [rudyCadabbyApi, xrefJobsData, isXrefConfigError, isXrefConfigLoading, isPollingJob, refetch]);

  useEffect(() => {
    if (isError) {
      console.log((error as { status: number })?.status);
      console.log(error, '<<<<< error');
      if ((error as { status: number })?.status === 403) navigate('/unauthorized');
      else if ((error as { originalStatus: number })?.originalStatus === 403) navigate('/unauthorized');
    }
  }, [isError, error, navigate]);
  /* ****************** Render ****************** */
  if (!isXrefConfigError && !isXrefConfigLoading && xrefJobsData && xrefJobsData.length > 0) return <JobsInProcess />;

  // if (isNoInheritanceSelected && !isXrefConfigLoading) {
  //   return (
  //     <>
  //       <NoDivisionSelected errorMessage="Please select a data inheritance level" />
  //     </>
  //   );
  // }

  return (
    <>
      <CreateInheritanceConfigModal />
      <AssignedRulesControls />
      <AssignedRulesContextMenu>
        <RulesTable
          onProductNumber={handleUpdateRecord}
          loading={tableLoading}
          dataSource={data}
          scroll={{ x: 'max-content' }}
          rowSelection={!isBusinessOnly ? rowSelectionConfig : undefined}
          onRow={(record): { onContextMenu: (event: MouseEvent) => void } => ({
            onContextMenu: (event): void => {
              event.preventDefault();
              if (record.isOverridden || isBusinessEdit) handleTableRightClick(record.id);
            }
          })}
          size="small"
          rowKey={(item): string => item.id}
          pagination={{ total: totalCount, defaultPageSize: floBearProductXrefParams.take, onChange: handlePageChange, pageSizeOptions: ['10', '20', '25', '50', '100'] }}
        />
      </AssignedRulesContextMenu>
      <AssignedRulesEditModal />
    </>
  );
};
