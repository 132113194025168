import { Col, Row } from 'antd';
import { BulkSearchModal } from 'components/molecules/BulkSearchModal';
import { ProductSearchActions } from 'components/molecules/ProductSearchActions';
import { ProductSearchFilters } from 'components/molecules/ProductSearchFilters';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { RulesNavigation } from './RulesNavigation';

export const ProductSearchControls = (): JSX.Element => {
  return (
    <Stack flexDirection="column" gap={16}>
      <AntPageTitle text="Add CustomerXref Items" />
      <Row justify="space-between">
        <Col>
          <ProductSearchFilters />
        </Col>
        <Col>
          <BulkSearchModal />
        </Col>
      </Row>
      <Stack justifyContent="space-between">
        <RulesNavigation />
        <ProductSearchActions />
      </Stack>
    </Stack>
  );
};
